import React, { useState } from 'react';
import { useContext } from 'react';
import { LanguageContext } from '../App/UserContext';
import {Link} from "react-router-dom"
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpeg";
import image4 from "./image4.jpeg";
import image5 from "./image5.jpeg";
import image6 from "./image6.jpg";
import image7 from "./image7.jpg";
import image8 from "./image8.jpeg";
import czech from "./czechflag.png";
import english from "./britishflag.png";

function Header(props) {
  let imageSrc, altText;
  const {language, setlanguage} = useContext(LanguageContext);
  const handleClickEn = () => {
    setlanguage('en'); 
  }

  const handleClickCz = () => {
    setlanguage('cz');
  }
  switch (props.Value) {
    case 1:
      imageSrc = image1;
      altText = 'image1';
      break;
    case 2:
      imageSrc = image2;
      altText = 'image2';
      break;
    case 3:
      imageSrc = image3;
      altText = 'image3';
      break;
    case 4:
      imageSrc = image4;
      altText = 'image4';
      break;
    case 5:
      imageSrc = image5;
      altText = 'image5';
      break;
    case 6:
      imageSrc = image6;
      altText = 'image6';
      break;
    case 7:
      imageSrc = image7;
      altText = 'image7';
      break;
      case 8:
        imageSrc = image8;
        altText = 'image8';
        break;
    default:
      imageSrc = image1;
      altText = 'Default Image';
  }

  return (
    <div id='pls' style={{ backgroundImage: `url(${imageSrc})`, backgroundRepeat: 'repeat',  backgroundPosition: 'center' }}>
    
      <div className= "Navbar">
      <ul>
          <li><Link to="/" className= "sideNavText">{language === 'en' ? 'About Us' : 'O Nás'}</Link></li>
          <li><Link to="/Galerie" className= "sideNavText">{language === 'en' ? 'Gallery' : 'Galerie'}</Link></li>
          <li><Link to="/Vyletyvokoli" className= "sideNavText">{language === 'en' ? 'trips around' : 'Výlety v okolí '}</Link> </li>
          <li><Link to="/Cenik" className= "sideNavText"> {language === 'en' ? 'Price list' : 'Ceník'}</Link> </li>   
          <li><Link to="/Jak" className= "sideNavText"> {language === 'en' ? 'How to get to us' : 'Jak se k nám dostat'}</Link></li>
          <li><Link to="/Rezervace" className= "sideNavText"> {language === 'en' ? 'Reservations' : 'Rezervace'}</Link></li>
          <li> <div className='grid-flag' ><div className='flag-item'><img src={czech} onClick={handleClickCz}
           className='flag' /></div> <div className='flag-item'><img src={english} onClick={handleClickEn}
           className='flag' /></div> </div> </li>
        </ul>
    </div>
    </div>
  );
}

export default Header;