import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Ryby() {
  const { language, setlanguage } = useContext(LanguageContext);
  function handleClick() {
    
    // Send data to the backend via POST
    //fetch('https://formsubmit.co/93cd5398553fa22862c11114d6b38e03', {  // Enter your IP address here

     // method: 'POST', 
    //  mode: 'cors', 
    //  body: JSON.stringify(jsonData) // body data type must match "Content-Type" header

   // })
    
  }
  return (
    <div className="grid">
      <header className="header">
        <Header Value={4}/>
      </header>
      <main className="main">
        <h1>{language === 'en' ? 'Reservations' : 'Rezervace'}</h1>
        <p> 
          {language === 'en' ? 'It is possible to reserve the Dalma Grebaštica apartment either' : 'Rezervaci apartmánu Dalma Grebaštica je možné provést buďto'} </p>
          <p>{language === 'en' ? 'by phone' : 'telefonicky'}</p>
          <h2><a href="tel:+420731610080">+420&nbsp;731&nbsp;610&nbsp;080</a></h2>
          <p>{language === 'en' ? 'or by email' : 'nebo emailem'}</p>
          <h2><a href="mailto:dalma.grebastica@gmail.com">dalma.grebastica@gmail.com</a></h2>
        <p>{language === 'en' ? 'You can reach us the same way if you have any questions.' : 'Stejným způsobem rádi zodpovíme i jakékoliv vaše dotazy.'} </p>

        <p>{language === 'en' ? 'We are looking forward to you!' : 'Těšíme se na vás!'}</p>


        <p>{language === 'en' ? 'The reservation system is under construction' : 'Na rezervačním systému pracujeme.'}</p>

            {/* <h1>Send Email</h1>
         <form action="https://formsubmit.co/93cd5398553fa22862c11114d6b38e03" method="POST">
        <div>
          <label>Subject:</label>
          <input
            type="text" required
          />
        </div>
        <div>
          <label>Message:</label>
          <textarea
           
          />
        </div>
        <button type="submit">Send Email</button>
      </form>
     */}
       
    
      </main>
      <footer className="footer">
        <Footer/>
      </footer>
    </div>
  );
}
export default Ryby;
