import React from "react";
import { useContext } from "react";
import { LanguageContext } from "../App/UserContext";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { ReactComponent as Shop } from './cart-shopping-solid.svg';
import { ReactComponent as Restaurant } from './utensils-solid.svg';
import { ReactComponent as Grill } from './grill-svgrepo-com.svg';
import { ReactComponent as Nostairs } from './Mediamodifier-Designn.svg';
import { ReactComponent as Parking } from './square-parking-solid.svg';
import { ReactComponent as Garden } from './garden-svgrepo-com.svg';
import { ReactComponent as Beach } from './umbrella-beach-solid.svg';
import { ReactComponent as Klimatizace } from './fan-solid.svg';
import { ReactComponent as Wifi} from './wifi-solid.svg';
import { ReactComponent as Osadnici} from './chess-solid.svg';
import { ReactComponent as Shower} from './shower-solid.svg';
import { ReactComponent as Kitchen} from './kitchen-set-solid.svg';
import { ReactComponent as ProtiKomar} from './mosquito-net-solid(1).svg';
import { ReactComponent as Postel} from './bed-solid.svg';
import { ReactComponent as TV} from './tv-solid.svg';
import { ReactComponent as Osoba} from './user-large-solid.svg';
import { ReactComponent as Letiště} from './plane-solid.svg';
import { ReactComponent as Fen} from './hairdryer-on-2.svg';
import { ReactComponent as Pracka} from './washing-machine-washing-machine-washingmachine-svgrepo-com.svg';
import { ReactComponent as Mycka} from './dishwasher-svgrepo-com.svg';
import { ReactComponent as Trouba} from './oven-roast-svgrepo-com.svg';
import { ReactComponent as Gauc} from './couch-solid.svg';
import { ReactComponent as Vchod} from './door-open-solid.svg';
import { ReactComponent as Dum} from './house-solid.svg';
import { ReactComponent as Blinds} from './solar-blind-svgrepo-com.svg';
import { ReactComponent as Kettle} from './electric-kettle-icon.svg';
import { ReactComponent as Paddle} from './8216180_standing_water_paddle board_paddleboard_paddleboarding_standup_sport.svg';
import { ReactComponent as Coffee} from './coffee-machine-icon.svg';
import { ReactComponent as Rice} from './10546531_cooking_kitchen_ricecooker_magicjar_tech_gadget_device_electronic.svg';
import { ReactComponent as Milk} from './5871891_foam_coffee_milk_frother.svg';

function Informace() {
  const {language, setlanguage} = useContext(LanguageContext);
  return (
    <div className="grid" >
      <header className="header">
      <Header Value={1}  />
      </header>
      <main className="main">

<h1>{language === 'en' ? 'Apartment Dalma Grebaštica' : 'Apartmán Dalma Grebaštica'}</h1>

       <p className="long">{language === 'en' ? 'Welcome to the website of our apartment, which is located in the picturesque Croatian village of Grebaštica in central Dalmatia. The peaceful and charming village has preserved the character of the original fishing village to this day. We wanted to create a place with a relaxed atmosphere where the visitor will feel at home. We believe we have succeeded!' : 'Vítejte na stránkách našeho apartmánu, který najdete v malebné chorvatské vesničce Grebaštica ve střední Dalmácii. Poklidná a půvabná víska si dodnes zachovala charakter původní rybářské osady. Přáli jsme si tu vytvořit místo s pohodovou atmosférou, kde se bude návštěvník cítit jako doma. Věříme, že se nám to povedlo!'}</p>
         <p className="long">{language === 'en' ? 'We offer modernly furnished accommodation with two bedrooms, a living room with a fully equipped kitchen and an outdoor seating area. An outdoor shower and electric grill are available on hot days. The apartment is wheelchair accessible, has its own entrance from the street and parking space for two cars. The apartment is only 200 meters from the sea, thus providing easy access to beautiful beaches and swimming in clear blue waters. The gradual entry into the sea from the pebble beach is ideal for children and water games. In season, there are also two inflatable water parks and trampolines by the beach. In addition to water fun, we also recommend sitting in the small harbor in one of the local restaurants, where you can soak up the atmosphere with a glass of Croatian white in hand. There is also a pizzeria near the apartment (100 meters away). There is also a grocery store nearby. In short, Grebaštica offers everything for a dream vacation.' : 'Nabízíme moderně zařízené ubytování se dvěma ložnicemi, obývacím prostorem s plně vybavenou kuchyní a venkovním posezením. V horkých dnech je k dispozici venkovní sprcha a elektrický gril. Apartmán je bezbariérový, má svůj vlastní vchod z ulice a parkovací stání pro dvě auta. Apartmán je pouhých 200 metrů od moře, tím pádem skýtá snadný přístup k nádherným plážím a koupání v průzračně modrých vodách. Pozvolný vstup do moře z oblázkové pláže je ideální pro děti a vodní hrátky. V sezoně jsou zde navíc dva nafukovací aquaparky a u pláže trampolíny. Kromě vodních radovánek doporučujeme i posezení v malém přístavu v některé z místních restaurací, kde lze nasávat atmosféru se sklenkou chorvatského bílého v ruce. V blízkosti apartmánu je i pizzerie (vzdálená 100 metrů). Nedaleko najdete také obchůdek s potravinami. Grebaštica zkrátka nabízí vše pro vysněnou dovolenou.'}</p>
<p className="long"> {language === 'en' ? 'And we are here to ensure maximum comfort and fulfill your wishes. Feel free to contact us with any questions, we’re happy to help. Thank you for your interest and we look forward to seeing you!' : 'A my jsme tu proto, abychom vám zajistili maximální pohodlí a splnili vaše přání. V případě dotazů nás neváhejte kontaktovat, rádi pomůžeme. Děkujeme za váš zájem a těšíme se na vás!'}</p>
      <div>
      <br/>
      <Osoba width="45" height="45" />
      <p>
       {language === 'en' ? 'The apartment is for a total of 4+1 people.' : 'Apartmán je celkově pro 4+1 osob.'}
      </p>
      <hr />
      <hr />
      <h2>
      {language === 'en' ? 'equipment of the apartment' : 'Vybavení apartmánu'}
      </h2>
      <div class="grid-onas-okolo"> 
      <div>
          <Paddle width="50" height="50" />
          <p>
            {language === 'en' ? 'Paddleboard' : 'Paddleboard'}
          </p>
          </div>
          <div>
          <Grill width="50" height="50" />
          <p>
             {language === 'en' ? 'Electric grill' : 'Elektrický gril'}
          </p>
          </div>

          <div>
          <Parking width="50" height="50" />
          <p>
              {language === 'en' ? '2 parking spots' : '2 parkovací místa'}
          </p>
          </div>
          <div>
          <Garden width="50" height="50" />
          <p>
             {language === 'en' ? 'front garden' : 'Předzahrádka'}
          </p>
          </div>
          <div>
          <Klimatizace width="50" height="50" />
          <p>
            {language === 'en' ? 'Air conditioning' : 'Klimatizace'}
          </p>
          </div>
          <div>
          <Wifi width="50" height="50" />
          <p>
          {language === 'en' ? 'Wifi' : 'Wifi'}
          </p>
          </div>
          <div>
          <Osadnici width="50" height="50" />
          <p>
          {language === 'en' ? 'Handpicked board games and card games' : ' Vybrané deskové a karetní hry'}
          </p>
          </div>
          <div>
          <Shower width="50" height="50" />
          <p>
          {language === 'en' ? 'Outside shower' : 'Venkovní sprcha'}
          </p>
          </div>
          <div>
          <Kitchen width="50" height="50" />
          <p>
            {language === 'en' ? 'Fully equipped kitchen' : 'Plně vybavená kuchyně'}
          </p> 
          </div>
          <div>
          <ProtiKomar width="50" height="50" />
          <p>
          {language === 'en' ? 'Mosquito nets in the windows' : 'Moskytiéry v oknech'}
          </p> 
          </div>
          <div>
          <Postel width="50" height="50" />
          <p>
         {language === 'en' ? '2+2 beds in two bedrooms' : '2+2 postele ve dvou ložnicích'}
          </p>
          </div>
          <div>
          <TV width="50" height="50" />
          <p>
           {language === 'en' ? 'Television' : 'Televize'}
          </p>
          </div>
          <div>
          <Gauc width="50" height="50" />
          <p>
            {language === 'en' ? 'Sofa that can be turned into a bed' : 'Rozkládací pohovka'}
          </p>
          </div>

          <div>
              <Fen width="50" height="50" />
              <p>
              {language === 'en' ? 'Hair dryer' : 'Fén na vlasy'}
            </p>
          </div>
          <div>
            <Pracka width="50" height="50" />
            <p>
                 {language === 'en' ? 'Washing machine' : 'Pračka'}
            </p>
          </div>
          <div>
          <Mycka width="50" height="50" />
          <p>
            {language === 'en' ? 'Dishwasher' : 'Myčka'}
          </p>
          </div>
          <div>
          <Trouba width="50" height="50" />
          <p>
           {language === 'en' ? 'Oven' : 'Trouba'}
          </p>
          </div>
          <div>
          <Blinds width="50" height="50" />
          <p>
          {language === 'en' ? 'Electric Blinds' : 'Elektrické Žaluzie'}
          </p>
          </div>
          <div>
          <Vchod width="50" height="50" />
          <p>
           {language === 'en' ? 'Separate entrance to the apartment' : ' Samostatný vchod do apartmánu'}
          </p>
          </div>
          <div>
          <Kettle width="50" height="50" />
          <p>
          {language === 'en' ? 'Kettle' : 'Rychlovarná konvice'}
          </p>
          </div>
          <div>
          <Coffee width="50" height="50" />
          <p>
            {language === 'en' ? 'Coffee maker' : 'Kávovar'}
          </p>
          </div>
          <div>
          <Rice width="50" height="50" />
          <p>
          {language === 'en' ? 'Ricecooker' : 'Rýžovar'}
          </p>
          </div>
          <div>
          <Milk width="50" height="50" />
          <p>
          {language === 'en' ? 'Milk frother/cocoa maker' : 'Pěnič mléka/kakao maker'}
          </p>
          </div>
      </div>
      
    
      <hr />
      <hr />
      <p>
       {language === 'en' ? 'In the immediate vicinity (among others)' : 'V bezprostředním okolí (mimo jiné)'}
      </p>
      <div class="grid-onas-okolo"> 
          <div><Shop width="50" height="50" />
              <p>
                 {language === 'en' ? 'Shop 150 meters from the apartment' : 'Obchod 150 metrů od apartmánu'}
              </p>
          </div> 
          <div>
            <Restaurant width="50" height="50" />
            <p>
             {language === 'en' ? 'Pizzeria 100 meters from the apartment' : 'Pizzerie 100 metrů od apartmánu'}
            </p>
          </div>
          <div> <Beach width="50" height="50" />
            <p>
            {language === 'en' ? 'Beach 200 meters from the apartment' : 'Pláž 200 metrů od apartmánu'}
            </p>
          </div>
      </div>
      <hr />
      <hr />
      <p>
        {language === 'en' ? 'To finish off, a few facts' : 'Na závěr pár faktů'}
      </p>
      <div><Dum width="50" height="50" />
      <p>
         {language === 'en' ? 'Apartment ' : 'Apartmán '}70 m<sup>2</sup>,
         {language === 'en' ? ' outside space ' : ' venkovní prostor '}30 m<sup>2</sup>
      </p></div>
       </div>   

      <div class="grid-onas-fakt"> <div><Nostairs width="50" height="50" />
      <p>
        {language === 'en' ? 'Entrance to the apartment is wheelchair accessible and barrier-free' : ' Vstup do apartmánu je bezbariérový'}
      </p></div>
      <div><Letiště width="50" height="50" />
      <p>
         {language === 'en' ? 'From the Split or Zadar airport you can reach us by car in an hour' : 'Z letiště ze Splitu i ze Zadaru je to hodinka autem'}
      </p></div>
    </div>
      </main>
      <footer className="footer">
        <Footer/>
      </footer>
    </div>
  );
}

export default Informace;
